<template>
  <div>
    <a-row class="mb-10" type="flex" justify="end">
      <a-col>
        <a-button
          size="small"
          @click="openManualEmission = true"
          style="font-size: 13px"
        >
          <a-icon type="logout" />
          Retirada manual
        </a-button></a-col
      >
    </a-row>

    <a-row class="travel-filters mt-0 mb-0 pt-0 pb-0 bt-0 bb-0" :gutter="20">
      <a-col :span="4">
        <div class="travel-input">
          <label  for class="filled">ID Operação</label>
          <a-input
            placeholder=""
            v-model="creditLetterTransaction.filters.operationId"
            @change="getCreditLetterTransactions()"
          />
        </div>
      </a-col>

      <a-col :span="4">
        <div class="travel-input">
          <label  for class="filled">ID contrato</label>
          <a-input
            placeholder=""
            v-model="creditLetterTransaction.filters.contractId"
            @change="getCreditLetterTransactions()"
          />
        </div>
      </a-col>

      <a-col :span="8">
        <div class="travel-input">
          <label  for class="filled">Operação</label>
          <a-select
            class="travel-input"
            placeholder="Selecione"
            mode="multiple"
            v-model="creditLetterTransaction.filters.operation.selected"
            show-search
            style="width: 100%"
            @change="getCreditLetterTransactions()"
          >
            <a-select-option
              v-for="(item, index) of creditLetterTransaction.filters.operation
                .list"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
      </a-col>

      <a-col v-if="$root.isAdmin()" :span="8">
        <div class="travel-input">
          <label  for class="filled">Usuários</label>
          <a-select
            class="travel-input"
            placeholder="Selecione"
            mode="multiple"
            v-model="creditLetterTransaction.filters.users.selected"
            optionFilterProp="txt"
            show-search
            style="width: 100%"
            @change="getCreditLetterTransactions()"
          >
            <a-select-option
              v-for="({ id, first_name, last_name }, index) of user.list"
              :key="index"
              :value="id"
              :txt="first_name"
            >
              {{ id }} - {{ first_name }} {{ last_name }}
            </a-select-option>
          </a-select>
        </div>
      </a-col>
    </a-row>

    <aTable
      class="travel-table"
      :columns="creditLetterTransactionTableColumns"
      :data-source="creditLetterTransaction.list"
      :loading="creditLetterTransaction.loading"
      :pagination="false"
      @change="creditLetterTransactionTableChange"
    >
      <div slot="value" slot-scope="record">
        <div :class="record.is_canceled == '1' ? 'line-through' : ''">
          {{ record.currency | formatCurrency }}
          {{ record.value | formatPrice }}
        </div>
      </div>

      <div slot="operation" slot-scope="record">
        <span v-html="formatOperation(record)"></span>

        <a-tooltip v-if="record.extra_info">
          <template slot="title">
            {{ record.extra_info }}
          </template>
          <a-icon
            class="relative c-pointer"
            style="font-size: 12px; color: #aaa; top: 2px"
            type="info-circle"
          />
        </a-tooltip>
      </div>

      <div slot="contract_id" slot-scope="record">
        {{ record.contract_id }}
      </div>

      <div slot="user" slot-scope="record">
        <div v-if="record.user.id">
          <a-tooltip>
            <template slot="title">
              {{ record.user.first_name }} {{ record.user.last_name }}
            </template>
            <span id="avatar">
              <a-avatar
                v-if="record.user.avatar"
                :src="record.user.avatar"
                :size="30"
              />
              <a-avatar
                v-else
                class="upper"
                style="color: #f56a00; background-color: #fde3cf"
              >
                {{ record.user.first_name.substring(0, 1)
                }}{{ record.user.last_name.substring(0, 1) }}
              </a-avatar>
            </span>
          </a-tooltip>
        </div>
        <div v-else><a-avatar icon="user" /></div>
      </div>

      <div slot="created" slot-scope="record">
        {{ record.created | formatDateTime }}
      </div>
    </aTable>

    <div class="a-center mt-30">
      <a-pagination
        show-size-changer
        :default-current="creditLetterTransaction.pagination.page"
        :page-size.sync="creditLetterTransaction.pagination.perPage"
        :total="creditLetterTransaction.pagination.total"
        @change="changeCreditLetterTransactionPage"
        @showSizeChange="creditLetterTransactionChangePageSize"
      >
      </a-pagination>
    </div>

    <a-modal
      width="300px"
      :visible="openManualEmission"
      :footer="false"
      @cancel="openManualEmission = false"
    >
      <template #title>
        <a-icon class="mr-5 red" type="logout" /> RETIRADA MANUAL
      </template>

      <CreditLetterManualEmission
        v-if="openManualEmission"
        :creditLetter="creditLetter"
        @onCreateTransaction="onCreateTransaction"
      />
    </a-modal>
  </div>
</template>

<script>
import creditLetterTransactionReusables from "../mixins/creditLetterTransactionReusables";
import reusableThings from "@/mixins/general/reusableThings";
import userMixins from "@/mixins/user/userMixins";
import CreditLetterManualEmission from "../modals/CreditLetterManualEmission.vue";

export default {
  name: "CreditLetterExtract",
  props: {
    creditLetter: Object,
  },
  components: { CreditLetterManualEmission },
  mixins: [creditLetterTransactionReusables, reusableThings, userMixins],
  data() {
    return {
      openManualEmission: false,
    };
  },
  beforeMount() {
    this.user.pagination.perPage = 100;
    this.getUsers();
  },
  async mounted() {
    this.creditLetterTransaction.filters.id = this.creditLetter.details.id;
    this.creditLetterTransaction.pagination.perPage = 10;
    await this.getCreditLetterTransactions();
    await this.$emit(
      "onCreateTransaction",
      this.creditLetterTransaction.pagination.total, false
    );
  },
  methods: {
    formatOperation(transaction) {
      let operation = "";
      if (transaction.operation === "subtract") {
        operation = `<font class="tag return">EMISSÃO</font>`;
      }

      if (transaction.operation === "refund") {
        operation = `<font class="tag ">REEMBOLSO</font> `;
      }

      if (transaction.operation === "canceled") {
        operation = `<font class="tag subtract red">CANCELADO</font>`;
      }

      return operation;
    },
    async onCreateTransaction() {
      this.openManualEmission = false;
      await this.getCreditLetterTransactions();
      await this.$emit(
        "onCreateTransaction",
        this.creditLetterTransaction.pagination.total, true
      );
    },
  },
};
</script>

<style lang="sass" scoped></style>
